import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import styles from '../styles/AudioChatPage.module.css';
import logo from '../assets/logo.webp';
import white from '../assets/white_audio.webp';
import blue from '../assets/blue_audio.webp';
import Loading from './Loading';
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {MicOffSvg, MuteMicButton} from "./navbar/MuteMicButton";
import {HeadphoneOffSvg, MuteAudioButton} from "./navbar/MuteAudioButton";
import SkipButton from "./navbar/SkipButton";
import PushToTalkButton from "./navbar/PushToTalkButton";
import FiltersButton from "./navbar/FiltersButton";
import { useNavigate } from 'react-router-dom';
import ReportPopUp from "./ReportPopUp";
import fire from '../assets/fire.png';

export default function AudioPage({ setReloadPage }){
    const [loading, setLoading] = useState(true);
    const [matched, setMatched] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [meSpeaking, setMeSpeaking] = useState(false);
    const [strangerSpeaking, setStrangerSpeaking] = useState(false);
    const [delay, setDelay] = useState(0);
    const [muteMic, setMuteMic] = useState(false);
    const [muteAudio, setMuteAudio] = useState(false);
    const [pushToTalk, setPushToTalk] = useState(false);
    const [remoteMicMuted, setRemoteMicMuted] = useState(false);
    const [remoteAudioMuted, setRemoteAudioMuted] = useState(false);
    const [strangerDisconnected, setStrangerDisconnected] = useState(false);

    const localAudio = useRef();
    const remoteAudio = useRef();
    const interestsRef = useRef();
    const socket = useRef();
    const makingOfferRef = useRef(false);
    const ignoreOfferRef = useRef(false);
    const navigate = useNavigate();

    const peerRef = useRef({
        connection: null,
        stream: null,
        audioContext: null,
        localAnalyser: null,
        remoteAnalyser: null,
        intervals: {
            stats: null,
            localAudio: null,
            remoteAudio: null
        }
    });

    const configuration = {
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'turn:freeturn.tel:5349', username: 'free', credential: 'free' }
        ]
    };

    useEffect(() => {
        const bodyClassList = document.querySelector("body").classList;
        if (loading) {
            bodyClassList.add(styles.loading_back);
            bodyClassList.remove(styles.audiochat_background);
        } else {
            bodyClassList.remove(styles.loading_back);
            bodyClassList.add(styles.audiochat_background);
        }
        return () => {
            bodyClassList.remove(styles.loading_back);
            bodyClassList.remove(styles.audiochat_background);
        }
    }, [loading]);

    const analyzeAudio = (analyser, who) => {
        return setInterval(() => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const volume = array.reduce((a, b) => a + b) / array.length;
            const db = 20 * Math.log10(volume).toFixed(2);
            const isSpeaking = db > -20;

            if (who === 'me') {
                setMeSpeaking(isSpeaking);
            } else {
                setStrangerSpeaking(isSpeaking);
            }
        }, 100);
    };

    const initializeWebRTC = (stream, isPolite) => {
        console.log("[INIT] Initializing WebRTC with polite =", isPolite);
        
        const pc = new RTCPeerConnection(configuration);
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const localAnalyser = audioContext.createAnalyser();
        const remoteAnalyser = audioContext.createAnalyser();

        peerRef.current = {
            connection: pc,
            stream,
            audioContext,
            localAnalyser,
            remoteAnalyser,
            intervals: {}
        };

        const localSource = audioContext.createMediaStreamSource(stream);
        localSource.connect(localAnalyser);
        peerRef.current.intervals.localAudio = analyzeAudio(localAnalyser, 'me');

        stream.getTracks().forEach(track => {
            console.log("[MEDIA] Adding track:", track.kind);
            pc.addTrack(track, stream);
        });

        peerRef.current.intervals.stats = setInterval(async () => {
            try {
                const stats = await pc.getStats();
                const rttStats = [...stats.values()]
                    .find(stat => stat.type === 'candidate-pair' && stat.state === 'succeeded');
                if (rttStats?.currentRoundTripTime) {
                    setDelay(rttStats.currentRoundTripTime * 1000);
                }
            } catch (error) {
                console.error('[STATS] Error:', error);
            }
        }, 1000);

        pc.addEventListener('track', event => {
            console.log("[TRACK] Remote track received");
            remoteAudio.current.srcObject = event.streams[0];
            const remoteSource = audioContext.createMediaStreamSource(event.streams[0]);
            remoteSource.connect(remoteAnalyser);
            peerRef.current.intervals.remoteAudio = analyzeAudio(remoteAnalyser, 'stranger');
        });

        pc.addEventListener('negotiationneeded', async () => {
            try {
                makingOfferRef.current = true;
                console.log("[NEGOTIATION] Making offer");
                await pc.setLocalDescription();
                socket.current.emit('offer', pc.localDescription);
            } catch (err) {
                console.error("[NEGOTIATION] Error:", err);
            } finally {
                makingOfferRef.current = false;
            }
        });

        pc.addEventListener('connectionstatechange', () => {
            console.log("[CONNECTION] State:", pc.connectionState);
            if (pc.connectionState === "failed") {
                pc.restartIce();
            }
        });

        pc.addEventListener('icecandidate', event => {
            if (event.candidate) {
                console.log("[ICE] Sending candidate");
                socket.current.emit('new-ice-candidate', event.candidate);
            }
        });

        return pc;
    };

    const setupSignaling = (pc, isPolite) => {
        socket.current.on('offer', async description => {
            try {
                const offerCollision = makingOfferRef.current || 
                                     pc.signalingState !== "stable";
                ignoreOfferRef.current = !isPolite && offerCollision;

                if (ignoreOfferRef.current) {
                    console.log("[OFFER] Ignoring collision");
                    return;
                }

                if (offerCollision && isPolite) {
                    console.log("[OFFER] Polite peer rolling back");
                    await Promise.all([
                        pc.setLocalDescription({type: "rollback"}),
                        pc.setRemoteDescription(description)
                    ]);
                } else {
                    await pc.setRemoteDescription(description);
                }

                await pc.setLocalDescription();
                socket.current.emit('answer', pc.localDescription);
            } catch (err) {
                console.error("[OFFER] Error:", err);
            }
        });

        socket.current.on('answer', async answer => {
            try {
                await pc.setRemoteDescription(answer);
            } catch (err) {
                console.error("[ANSWER] Error:", err);
            }
        });

        socket.current.on('new-ice-candidate', async candidate => {
            try {
                await pc.addIceCandidate(candidate);
            } catch (err) {
                if (!ignoreOfferRef.current) {
                    console.error("[ICE] Error:", err);
                }
            }
        });
    };

    const cleanupWebRTC = () => {
        if (peerRef.current.connection) {
            peerRef.current.connection.close();
        }
        if (peerRef.current.audioContext) {
            peerRef.current.audioContext.close();
        }
        Object.values(peerRef.current.intervals).forEach(interval => {
            if (interval) clearInterval(interval);
        });
        if (peerRef.current.stream) {
            peerRef.current.stream.getTracks().forEach(track => {
                track.stop();
                peerRef.current.stream.removeTrack(track);
            });
        }
    };

    const showInterestPopUp = (interest) => {
        interestsRef.current.style.display = 'flex';
        interestsRef.current.querySelector('p').textContent = `INTEREST: ${interest}`;
    };

    const hideInterestPopUp = () => {
        interestsRef.current.style.display = 'none';
    };

    const handleMatchFound = (matchData) => {
        console.log('[MATCH] Found with data:', matchData);
        setTimeout(() => {
            setLoading(false);
            setMatched(true);
            setTimeout(() => {
                if (matchData.sameInterests?.length > 0) {
                    showInterestPopUp(matchData.sameInterests);
                }
            }, 500);
            remoteAudio.current.play().catch(console.error);
        }, 1500);
    };

    const handleUserLeft = () => {
        document.querySelector("#root > div > div > div:nth-child(2)")?.classList.add(styles.disconnected);
        setStrangerDisconnected(true);
        clickSkip();
    };

    const handleMicMuted = (muted) => setRemoteMicMuted(muted);
    const handleAudioMuted = (muted) => setRemoteAudioMuted(muted);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(mediaStream => {
                localAudio.current = new Audio();
                remoteAudio.current = new Audio();
                localAudio.current.srcObject = mediaStream;

                const language = navigator.language.split('-')[0];
                const interests = new URLSearchParams(window.location.search).get('interests');
                
                socket.current = io('https://meyou.social:4000', {
                    query: `audio=true&language=${language}` + (interests ? `&interests=${interests}` : ''),
                    reconnection: true,
                    reconnectionAttempts: 5
                });

                socket.current.on('match_found', ({ isPolite, ...matchData }) => {
                    console.log("[MATCH] Found, polite =", isPolite);
                    const pc = initializeWebRTC(mediaStream, isPolite);
                    setupSignaling(pc, isPolite);
                    handleMatchFound(matchData);
                });

                socket.current.on('user_left', handleUserLeft);
                socket.current.on('mute-mic', handleMicMuted);
                socket.current.on('mute-audio', handleAudioMuted);
            })
            .catch(error => {
                console.error("[MEDIA] Access error:", error);
                alert('Error accessing microphone. Please grant microphone permissions.');
                navigate('/');
            });

        return () => {
            cleanupWebRTC();
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);

    const clickMuteMic = () => {
      if (localAudio.current && matched) {
        localAudio.current.srcObject.getAudioTracks().forEach(track => {
          track.enabled = !track.enabled;
        });
        socket.current.emit('mute-mic', !muteMic);
        setMuteMic(!muteMic);
      }
    };
    const clickMuteAudio = () => {
      if (remoteAudio.current && matched) {
        remoteAudio.current.muted = !muteAudio;
        socket.current.emit('mute-audio', !muteAudio);
        setMuteAudio(!muteAudio);
      }
    };
    const clickPushToTalk = () => {
      if (localAudio.current && matched) {
        localAudio.current.srcObject.getAudioTracks().forEach(track => {
          track.enabled = true;
        });
        setPushToTalk(true);
        socket.current.emit('mute-mic', false);
        setMuteMic(false);
      }
    };
    const releasePushToTalk = () => {
      if (localAudio.current && matched) {
        localAudio.current.srcObject.getAudioTracks().forEach(track => {
          track.enabled = false;
        });
        setPushToTalk(false);
        socket.current.emit('mute-mic', true);
        setMuteMic(true);
      }
    };
    const clickSkip = (type) => {
      setMatched(false);
      if (peerRef.current.connection) {
        peerRef.current.connection.close();
      }
      if (socket.current) {
        socket.current.disconnect();
      }
    };
    const clickFilter = () => {
      alert("Questa funzione verrà implementata in futuro");
    };
    const clickReport = () => setShowReport(true);
    const reportUser = () => {
      //TODO: implementare la funzione di report
      clickSkip();
    };
    return (
      <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.interests} ref={interestsRef}>
              <div>
                <img src={fire} alt="fire" />
                <div>
                  <h2>YOU HAVE FOUND A <span>MATCH</span>!</h2>
                  <p>INTEREST: </p>
                </div>
                <button onClick={hideInterestPopUp}>OK<svg width="15" height="15" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="7.08206" height="20.8941" rx="3.54103" transform="matrix(0.88373 -0.467997 0.508095 0.861301 -0.958374 9.88965)" fill="black" fill-opacity="0.99"/>
              <rect width="7.13434" height="30.6905" rx="3.56717" transform="matrix(-0.87063 -0.491938 0.523942 -0.851754 12.6896 28.4346)" fill="black" fill-opacity="0.99"/>
              </svg>
              </button>
              </div>
          </div>
          <div className={styles.header} >
          <svg onClick={() => {navigate("/")}} width="60" height="60" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3705 0.64669C19.7558 0.283532 20.3573 0.283533 20.7425 0.646691L35.5934 14.6473C36.252 15.2682 35.8126 16.3749 34.9074 16.3749H5.20559C4.30041 16.3749 3.86098 15.2682 4.51962 14.6473L19.3705 0.64669Z" fill="#81F7FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8C11.2386 8 9 10.2386 9 13V28.7065C9 29.8111 9.89543 30.7065 11 30.7065H16.0622C16.0213 30.4772 16 30.2411 16 30V24C16 22.3431 17.3431 21 19 21H21C22.6569 21 24 22.3431 24 24V30C24 30.2411 23.9787 30.4772 23.9378 30.7065H29.1902C30.2947 30.7065 31.1902 29.8111 31.1902 28.7065V13C31.1902 10.2386 28.9516 8 26.1902 8H14Z" fill="#81F7FB"/>
                    </svg>
            <img src={logo} alt="logo" width="200px" />
            <svg onClick={clickReport} width="60" height="60" viewBox="0 0 61 58" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '6px'}}>
              <path d="M27.081 5.63071C28.6386 3.06549 32.3614 3.0655 33.919 5.63071L53.2243 37.4239C54.8429 40.0896 52.9239 43.5 49.8052 43.5H11.1948C8.07612 43.5 6.15707 40.0896 7.77571 37.4239L27.081 5.63071Z" fill="#81F7FB"/>
              <path d="M32.8821 18.5455L32.4389 30.5455H28.5526L28.1094 18.5455H32.8821ZM30.4957 36.2727C29.8366 36.2727 29.2713 36.0426 28.7997 35.5824C28.3338 35.1165 28.1037 34.5511 28.1094 33.8864C28.1037 33.2386 28.3338 32.6847 28.7997 32.2244C29.2713 31.7642 29.8366 31.5341 30.4957 31.5341C31.1207 31.5341 31.6719 31.7642 32.1491 32.2244C32.6321 32.6847 32.8764 33.2386 32.8821 33.8864C32.8764 34.3295 32.7599 34.733 32.5327 35.0966C32.3111 35.4545 32.0213 35.7415 31.6634 35.9574C31.3054 36.1676 30.9162 36.2727 30.4957 36.2727Z" fill="#1B1B1B"/>
            </svg>
          </div>
          <div className={styles.container}>
              <div>
              <aside>
                { muteMic && <div>
                  <MicOffSvg />
                </div>
                }
                { muteAudio && <div>
                  <HeadphoneOffSvg />
                </div>
                }
              </aside>
                <div id="me" className={meSpeaking ? styles.speaking : ''}>
                  <img src={blue} alt="blue" width="100px" />
                </div>
              </div>
              <div>
              <aside>
                { remoteMicMuted && <div>
                  <MicOffSvg />
                </div>
                }
                { remoteAudioMuted && <div>
                  <HeadphoneOffSvg />
                </div>
                }
              </aside>
                <div id="stranger" className={strangerSpeaking ? styles.speaking : ''}>
                  <img src={white} alt="white" width="100px" />
                  {strangerDisconnected && <span>DISCONNECTED</span>}
                </div>
              </div>
          </div>
          <div className={styles.info}>
            <FontAwesomeIcon icon={faWifi} />
            <span>{delay}</span>
            <span>ms</span>
          </div>
          <div className={styles.controls}>
            <div>
              <PushToTalkButton pressed={pushToTalk} clickFunction={clickPushToTalk} releaseFunction={releasePushToTalk} />
              <MuteAudioButton mute={muteAudio} clickFunction={clickMuteAudio} />
              <SkipButton matched={matched} clickFunction={clickSkip} reload={setReloadPage} />
              <MuteMicButton mute={muteMic} clickFunction={clickMuteMic} />
              <FiltersButton clickFunction={clickFilter} />
            </div>
          </div>
          <ReportPopUp show={showReport} setShow={setShowReport} yesFunction={reportUser} word="recording"/>
        </div>
    )}
    </>
  );
}